import './App.css';
import { createRef, useState } from "react";
import ParticleBackground from 'react-particle-backgrounds';
import FR from "./Images/FR-512x512.png";
import GitHub from "./Components/Github";
import GameIcon from './Components/Game';
import Resume from './Components/Resume';
import Hoops from './Components/Hoops';
import CursorTrail from './Components/Tail';
import { Col, Container } from 'react-bootstrap';

function App() {
  const [trail, setTrail] = useState(false);

  const gameRef = createRef(null);
  const ghRef = createRef(null);
  const cvRef = createRef(null);
  const hoopRef = createRef(null);

  const addClass = () => {
    const divgame = gameRef.current;
    const divgh = ghRef.current;
    const divcv = cvRef.current;
    const divHp = hoopRef.current;
    divgame.classList.add("visibility");
    divgh.classList.add("visibility");
    divcv.classList.add("visibility");
    divHp.classList.add("visibility")
  }


  const handleOnMouseEnter = (e) => {
    e.target.classList.remove("Heart");
    e.target.classList.add("FR-bg");
    setTrail(true);
    addClass();
  }

  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false
    },
    particle: {
      particleCount: 50,
      color: "rgb(255, 245, 140)",
      minSize: 1,
      maxSize: 4
    },
    velocity: {
      minSpeed: 0.2,
      maxSpeed: 0.4
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.6,
      opacityTransitionTime: 10000
    }
  }
  return (
    <>
      <div className='app'>
    {trail && <CursorTrail/>}

    <ParticleBackground settings={settings} className={"particles-bg"}/>
        <Container className={"container-logo"}>
          <br />
          <br />


          <img src={FR} className={"propic Heart "}
            onMouseOver={(e) => handleOnMouseEnter(e)}
            alt="FR"
            style={{width:"17rem", height:"17rem"}}
          />
          <br />
          <br />
        </Container>
        <Container className='container-icons'>
              <div ref={ghRef} className="icons2 gh"> 
              <GitHub onMouseEnter={(() => setTrail(false))} onMouseLeave={() => setTrail(true)}/>
              </div>
            <Col md="auto">
              <div ref={cvRef} className="icons2 cv">
              <Resume onMouseEnter={(() => setTrail(false))} onMouseLeave={() => setTrail(true)}/>
              </div>
            </Col>
              <div ref={gameRef} className="icons vi">
              <GameIcon onMouseEnter={(() => setTrail(false))} onMouseLeave={() => setTrail(true)}/>
              </div>
              <div ref={hoopRef} className="icons hp">
              <Hoops onMouseEnter={(() => setTrail(false))} onMouseLeave={() => setTrail(true)}/>
              </div>
         </Container>
      </div>
    </>
  )
}

export default App;