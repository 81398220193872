import React, { useState, useEffect } from "react";
import "./Tail.css";

const CursorTrail = () => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const onMouseMove = (e) => {
      setPositions((prevPositions) => [
        ...prevPositions,
        { x: e.clientX, y: e.clientY },
      ]);
    };

    document.addEventListener("mousemove", onMouseMove);
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <>
      {positions.map((position, index) => (
        <div
          key={index}
          className="cursor-trail"
          style={{ left: `${position.x}px`, top: `${position.y}px` }}
        />
      ))}
    </>
  );
};

export default CursorTrail;
