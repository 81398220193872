import { useRef } from "react";
import Draggable from 'react-draggable';
import HoopsLogo from "../Images/hoopsBlack.jpeg";
import { Link, Tooltip } from "@mui/material";

function Hoops(props) {

    const nodeRef = useRef(null);

    return (
        <>
            <Link href="http://hoops.onrender.com/" target="_blank">
                <Draggable nodeRef={nodeRef}>
                    <Tooltip title={'try my web app!'}>
                        <img ref={nodeRef} src={HoopsLogo} className={"icon"} alt={'hoops'}
                        onMouseEnter={props.onMouseEnter} 
                        onMouseLeave={props.onMouseLeave}/>
                    </Tooltip>
                </Draggable>
            </Link>
        </>
    )
}

export default Hoops;