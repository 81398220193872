import { useRef } from "react";
import Draggable from 'react-draggable';
import GHlogo from "../Images/githubLogo.png";
import { Link, Tooltip } from "@mui/material";

function Github(props) {

    const nodeRef = useRef(null);

    return (
        <><div>
            <Link href="https://github.com/rpiflv" target="_blank">
                <Draggable nodeRef={nodeRef}>
                    <Tooltip title={'check out my Git Hub!'}>
                        <img ref={nodeRef} src={GHlogo} className={"icon"} alt={'github'}
                        onMouseEnter={props.onMouseEnter} 
                        onMouseLeave={props.onMouseLeave}/>
                    </Tooltip>
                </Draggable>
            </Link>
        </div>
        </>
    )
}

export default Github;