import { useRef } from "react";
import Draggable from 'react-draggable';
import cv from "../Images/cv.png";
import { Tooltip, Link } from "@mui/material";
import resume from '../data/Flavio-Ripa-Resume-20240202.pdf';

function Resume(props) {
    const nodeRef = useRef(null);

    return (
        <>
            <Link href={resume} download target="_blank">
                <Draggable nodeRef={nodeRef}>
                <Tooltip title={'download my resume'}>
                    <img ref={nodeRef} src={cv} className={"icon"} alt={'cv'} 
                    onMouseEnter={props.onMouseEnter} 
                    onMouseLeave={props.onMouseLeave}/>
                </Tooltip>
                </Draggable>
            </Link>
        </>
    )
}

export default Resume;