import { useRef } from "react";
import Draggable from 'react-draggable';
import gamepic from "../Images/spaceinvaders.jpg";
import { Link, Tooltip } from "@mui/material";

function GameIcon(props) {
    const nodeRef = useRef(null);

    return (
        <>
            <Link href="https://rpiflv.itch.io/virus-invasion" target="_blank">
                <Draggable nodeRef={nodeRef}>
                    <Tooltip title={"play a game"}>
                        <img ref={nodeRef} src={gamepic} className={"icon"} alt={'game'} 
                        onMouseEnter={props.onMouseEnter} 
                        onMouseLeave={props.onMouseLeave}/>
                    </Tooltip>
                </Draggable>
            </Link>
        </>
    )
}

export default GameIcon;